<template>
  <div>
    <vs-chip :class="state" class="leading-none mt-1">
      {{ $t('pricing.state.' + state) }}
    </vs-chip>
  </div>
</template>

<script>
export default {
  name: 'PricingListState',
  props: {
    state: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.start {
  background-color: #f3f3f3;
}
.work_in_progress {
  background-color: #d7d7d7;
}
.pricing_validator_review {
  background-color: #fd9626;
}
.pricing_validator_approved {
  background-color: #7fa8ff;
}
.assembling {
  background-color: #ffc800;
}
.assembled {
  background-color: #ab9fff;
}
.published {
  background-color: #2c5120;
  color: white !important;
}
</style>
