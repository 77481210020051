<template>
  <div id="pricing-list">
    <div class="vx-card p-6">
      <vs-table ref="table" class="w-100 my-4" stripe sst max-items="3" :data="pricingsData" @sort="onTableSort">
        <template slot="header">
          <!-- ITEMS PER PAGE -->
          <div class="flex-grow my-3">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ $t('pricing.list.row_by_page_switcher', { rowStart, rowEnd, totalRow: totalItems }) }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="pageSize in pageSizeList" @click="paginationPageSize=pageSize" :key="pageSize">
                  <span>{{ pageSize }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <vs-button v-if="$acl.check('pricingEditor')" class="mr-8" type="relief" icon="post_add" @click="promptNewPricing">{{ $t('pricing.list.link.create_pricing') }}</vs-button>

          <!-- TABLE ACTION COL-2: SEARCH -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" :placeholder="$t('pricing.list.search.placeholder')" />
        </template>

        <template slot="thead">
          <vs-th sort-key="date">
            {{ $t('pricing.list.table.header.date') }}

            <div class="filter w-full" @click.stop>
              <datepicker class="w-80" @input="filterDate" :language="langFr" v-model="filterValue.date"
                          :fullMonthName="true" minimumView="month" :monday-first="true" initialView="month" format="MMMM yyyy" name="date" />
              <vs-button v-if="filterValue.date" color="primary" type="border" icon="replay" @click="resetDate"></vs-button>
            </div>
          </vs-th>

          <vs-th sort-key="version">
            {{ $t('pricing.list.table.header.version') }}

            <div class="filter w-full" @click.stop>
              <vs-input-number min="0" v-model="filterValue.version" @input="filterVersion"/>
            </div>
          </vs-th>

          <vs-th sort-key="type">
            {{ $t('pricing.list.table.header.type') }}
            <div class="filter w-full" @click.stop>
              <pricing-type-select :show-all="true" v-model="filterValue.type" @input="filterType" autocomplete />
            </div>
          </vs-th>

          <vs-th sort-key="state">
            {{ $t('pricing.list.table.header.state') }}

            <div class="filter w-full" @click.stop>
              <vs-select class="ml-auto my-2 cursor-pointer w-full" v-model="filterValue.state" @input="filterState" autocomplete>
                <vs-select-item key="" value="" :text="pricingStateText('all')" />
                <vs-select-item :key="state" :value="state" :text="pricingStateText(state)" v-for="state of pricingStateList"/>
              </vs-select>
            </div>
          </vs-th>

          <vs-th sort-key="update_at">
            {{ $t('pricing.list.table.header.update_at') }}

            <div class="filter w-full" @click.stop>
              <datepicker @input="filterUpdatedAt" :monday-first="true" :language="langFr" name="updatedAt" v-model="filterValue.updatedAt"/>
              <vs-button v-if="filterValue.updatedAt" color="primary" type="border" icon="replay" @click="resetUpdatedAt"></vs-button>
            </div>
          </vs-th>
          <vs-th>{{ $t('pricing.list.table.header.actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].date">
              {{ dateFormatter(data[indextr].date) }}
            </vs-td>
            <vs-td :data="data[indextr].version">
              {{ data[indextr].version }}
            </vs-td>
            <vs-td :data="data[indextr].type">
              {{ $t(`pricing.pricing_type.list.${data[indextr].type.name}`) }}
            </vs-td>
            <vs-td :data="data[indextr].state">
              <pricing-list-state :state="data[indextr].state" />
            </vs-td>
            <vs-td :data="data[indextr].updatedAt">
              {{ updatedAtFormatter(data[indextr].updatedAt) }}
            </vs-td>
            <vs-td>
              <pricing-list-actions :data="data[indextr]" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />
    </div>

    <ValidationObserver v-slot="{ invalid }">
      <vs-prompt
        :title="$t('pricing.new.page.title')"
        :accept-text="$t('pricing.form.submit')"
        :active.sync="activePromptNewPricing"
        :is-valid="!invalid"
        @accept="saveNewPricing">
        <div class="flex">
          <ValidationProvider :name="$t('pricing.form.input.date')" class="w-full" rules="required" v-slot="{ errors }">
            <small class="date-label">{{ $t('pricing.form.input.date') }}</small>
            <datepicker class="w-full" :monday-first="true" :language="langFr" :fullMonthName="true" minimumView="month" initialView="month" format="MMMM yyyy" name="date" v-model="pricing.date" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="flex">
          <ValidationProvider :name="$t('pricing.form.input.type')" class="w-full" rules="required" v-slot="{ errors }">
            <pricing-type-select :label="$t('pricing.form.input.type')" v-model="pricing.type" autocomplete/>
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'

import PricingTypeSelect from '../pricing-edit/form/input/PricingTypeSelect'

import { DateTime } from 'luxon'

//store
import modulePricingMixin from '@/store/pricing/modulePricingMixin'

// CellRenderer
import PricingListActions from './cell-renderer/CellRendererActions'
import PricingListState from './cell-renderer/CellRendererState'

export default {
  components: {PricingListActions, PricingListState, PricingTypeSelect, Datepicker},
  mixins: [modulePricingMixin],
  data () {
    return {
      currentPage: 1,
      paginationPageSize: 10,
      pageSizeList: [10, 20, 25, 30],
      maxPageNumbers: 7,
      searchQuery: '',
      sortItem: {
        order: {
          ['date']: 'desc',
          ['type.name']: 'desc',
          ['version']: 'desc'
        }
      },
      filterItem: {},
      filterValue: {
        version: 0,
        type: '',
        state: ''
      },
      searchItem: {},
      pricing: {},
      activePromptNewPricing: false,
      langFr: fr,
      pricingStateList: []
    }
  },
  computed: {
    pricingsData () {
      return this.$store.state.pricing.pricings
    },
    totalItems () {
      return this.$store.state.pricing.totalItems
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.paginationPageSize)
    },
    rowStart () {
      return (this.currentPage * this.paginationPageSize) - (this.paginationPageSize - 1)
    },
    rowEnd () {
      if (this.pricingsData.length - (this.currentPage * this.paginationPageSize) > 0) {
        return this.currentPage * this.paginationPageSize
      }

      return this.pricingsData.length
    }
  },
  watch: {
    '$store.state.windowWidth' (val) {
      if (val <= 576) {
        this.maxPageNumbers = 4
      }
    },
    'currentPage' () {
      this.fetchPricings()
    },
    'paginationPageSize' () {
      if (this.rowStart > this.totalItems) {
        this.currentPage = Math.ceil(this.totalItems / this.paginationPageSize)
        return
      }
      this.fetchPricings()
    },
    'filterItem' () {
      this.fetchPricings()
      this.currentPage = 1
    }
  },
  methods: {
    updateSearchQuery () {
      if (this.searchQuery === '') {
        this.searchItem = {}
        this.fetchPricings()
        return
      }

      this.searchItem = {
        'state[ior]': this.searchQuery,
        'type.name[ior]': this.searchQuery
      }

      this.fetchPricings()
    },
    fetchPricings () {
      const payload = {
        page: this.currentPage,
        itemsPerPage: this.paginationPageSize,
        ...this.sortItem,
        ...this.searchItem,
        ...this.filterItem
      }
      this.$store.dispatch('pricing/fetchPricings', payload)
        .catch(err => { console.error(err) }) // eslint-disable-line no-console
    },
    onTableSort (field, order) {
      this.sortItem = {}
      if (order) {
        this.sortItem = {
          order: {
            [field]: order
          }
        }
      }
      this.fetchPricings()
    },
    filterDate (date) {
      date = DateTime.fromJSDate(date).setZone('UTC').set({ hour: 0, minute: 0, second: 0 })

      this.filterItem = {
        ...this.filterItem,
        date: {
          'strictly_after': date.minus({ days: 1}).toISODate(),
          'strictly_before': date.plus({ days: 1}).toISODate()
        }
      }
    },
    resetDate () {
      this.$delete(this.filterItem, 'date')
      delete this.filterValue.date
    },
    filterVersion () {
      if (typeof this.filterItem.version !== 'undefined' && this.filterValue.version === '0') {
        this.$delete(this.filterItem, 'version')
        return
      }

      this.filterItem = {
        ...this.filterItem,
        version: this.filterValue.version
      }
    },
    filterType () {
      if (typeof this.filterItem.type !== 'undefined' && this.filterValue.type === '') {
        this.$delete(this.filterItem, 'type')
        return
      }

      this.filterItem = {
        ...this.filterItem,
        type: {
          'exact': this.filterValue.type
        }
      }
    },
    filterState () {
      if (typeof this.filterItem.state !== 'undefined' && this.filterValue.state === '') {
        this.$delete(this.filterItem, 'state')
        return
      }

      this.filterItem = {
        ...this.filterItem,
        state: {
          'exact': this.filterValue.state
        }
      }
    },
    filterUpdatedAt (date) {
      date = DateTime.fromJSDate(date, { zone: 'UTC' }).set({ hour: 0, minute: 0, second: 0 })

      this.filterItem = {
        ...this.filterItem,
        updatedAt: {
          'strictly_after': date.minus({ days: 1}).toISODate(),
          'strictly_before': date.plus({ days: 1}).toISODate()
        }
      }
    },
    resetUpdatedAt () {
      this.$delete(this.filterItem, 'updatedAt')
      delete this.filterValue.updatedAt
    },
    promptNewPricing () {
      this.activePromptNewPricing = true
    },
    saveNewPricing () {
      this.$store.dispatch('pricing/addPricing', this.pricing)
        .then((response) => {
          this.$router.push({'name': 'pricing-edit', 'params': { pricingId: response.data.id }})
          this.$vs.notify({
            color: 'success',
            title: this.$t('pricing.form.submit.success.notify.title'),
            text: this.$t('pricing.form.submit.success.notify.text', { pricingName: response.data.type.name, date: this.pricing.date })
          })
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('pricing.form.submit.error.notify.title'),
            text: this.$t('pricing.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    },
    dateFormatter (date) {
      const options = { year: 'numeric', month: 'long'}
      const dtf = new Intl.DateTimeFormat(['fr', 'en'], options)
      return dtf.format(new Date(date))
    },
    updatedAtFormatter (date) {
      const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
      const dtf = new Intl.DateTimeFormat(['fr', 'en'], options)
      return dtf.format(new Date(date))
    },
    pricingStateText (name) {
      return this.$t(`pricing.state.${name}`)
    }
  },
  mounted () {
    this.fetchPricings()
    this.pricingStateList = JSON.parse(process.env.VUE_APP_WORKFLOW_PRICING)
  }
}
</script>

<style>
th .vs-table-text {
  flex-direction: column;
}

.filter .vdp-datepicker .vdp-datepicker__calendar{
  z-index: 1000;
}
.con-tablex.vs-table--content{
  overflow: visible;
}
.vs-con-tbody.vs-table--tbody {
  overflow: visible;
}
</style>
